@import "~@splidejs/splide/dist/css/splide-core.min.css";
@import "~@splidejs/splide/dist/css/splide.min.css";

.splide__toggle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.splide__progress__bar {
  height: 3px;
  background: #ccc;
}

.splide__slide {
  figure {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
    }
  }
}
